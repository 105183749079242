.banner-container {
    background-color: #E4006F;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.banner-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 95px 98px 0 105px;
    align-items: flex-start;
    z-index: 110;
}

.banner-date {
    color: white;
    font-size: 76px;
    font-weight: 700;
    line-height: 74.5px;
}