.settings-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-content {
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal-banner {
    width: 100%;
    margin: 0 0px;
    display: flex;
    max-width: 1080px;
    padding: 120px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.generate-button {
    text-transform: none;
    color: white;
    background-color: #007bff;
    border-radius: 16px;
    padding: 0 24px;
}
.generate-button:hover {
    background-color: #0056b3;
}