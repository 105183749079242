.sales-block-container {
    position: absolute;
    left: -196px;
    bottom: 384px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    height: 1000px;
    z-index: 101;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    pointer-events: none;
}

.sales-block-container_1080 {
    position: absolute;
    left: 212px;
    bottom: 362px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    pointer-events: none;
}

.sales-block-container_1920 {
    position: absolute;
    left: -254.59px;
    top: 84.77px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1170.45px;
    height: 1170.45px;
    z-index: 101;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    pointer-events: none;
}

.flower-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}
.flower-img-container {
    /*position: absolute;*/
    /*bottom: -162px;*/
    /*top: -60px;*/

    width: 100%;
    height: 100%;

}

.image-container {
    position: absolute;
    /*right: 103px;*/
    top: 786px;
    width: 644px;
    height: 644px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.text-container {
    position: absolute;
    top: 264px;
    right: 32px;
    width: 434px;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}
.text-container-1080vertical {
    position: absolute;
    top: 766px;
    left: 32px;
    width: 434px;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}

.text-container-1350vertical {
    position: absolute;
    top: 804px;
    left: 32px;
    width: 434px;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}

.text-container_1920 {
    position: absolute;
    top: 426px;
    right: 32px;
    width: 370px;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}

.text {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 40px;
    font-weight: 700 !important;
    word-wrap: break-word;
}
/* 1080x1350 */
.image_1080x1350_square {
    background-color: crimson;
    position: absolute;
    /*right: 103px;*/
    top: 786px;
    width: 532px;
    height: 532px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1350_horizontal {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 558px;
    width: 987px;
    height: 987px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1350_vertical {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 331px;
    left: 315.5px;
    width: 987px;
    height: 987px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* 1080x1920 */
.image_1080x1920_square {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 1064px;
    width: 824px;
    height: 824px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1920_horizontal {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 786px;
    width: 900px;
    height: 900px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1920_vertical {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 786px;
    width: 1016px;
    height: 1016px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* 1080x1080 */
.image_1080x1080_square {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 482px;
    width: 657.78px;
    height: 657.78px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1080_vertical {
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 148px;
    left: 375px;
    width: 900px;
    height: 900px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.image_1080x1080_horizontal{
    position: absolute;
    background-color: crimson;
    /*right: 103px;*/
    top: 431px;
    width: 828px;
    height: 828px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.sale-block {
    position: relative;
}

.sale-percent {
    width: fit-content;
    background-color: #F5146E;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    padding: 31px 33.3px 15.86px 19.1px;
}

.sale-percent-text {
    margin: 0;
    color: #FCE300;
    font-size: 142.5px !important;
    line-height: 94px !important;
    font-weight: 700 !important;
    letter-spacing: -1.427px;
}

.sale-info {
    position: relative;
    width: 100%;
    height: 205.57px;
    background-color: #FCE300;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.sale-tag {
    position: absolute;
    top: 14.08px;
    left: 39.42px;
}

.sale-tag-text {
    line-height: 32px !important;
    letter-spacing: -0.676px !important;
    font-weight: 700 !important;
    font-size: 33.8px !important;
}

.sale-price {
    position: absolute;
    /*top: 26.28px;*/
    /*right: 19.96px;*/
}

.price_text {
    line-height: 44px !important;
    color: black;
    font-weight: 700 !important;
    font-size: 60px !important;
    letter-spacing: -0.6px!important;
}

.price_text::before {
    content: '';
    border-bottom: 5px solid red;
    position: absolute;
    width: 100%;
    height: 48%;
    transform: rotate(-15deg);
}

.sale-discounted-price {
    position: absolute;
    bottom: 30.31px;
    right: 20.64px;

}

.discounted_price_text {
    color: black;
    font-weight: 700 !important;
    font-size: 124px !important;
    letter-spacing: -1.098px !important;
    line-height: 84px !important;
}

.image_1080x1080_horizontal{
    position: absolute;
    background-color: inherit;
    /*right: 103px;*/
    top: 19px;
    right: 15px;
    width: 743px;
    height: 743px;
    align-self: center;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}